import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-congstar.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/congstar-partnernet/Projekte_congstar.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const CongstarPartnerwelt = function (props, ref) {

    const data = useStaticQuery(graphql`
        query CongstarPartnerweltQuery {
            heropic: file(relativePath: { eq: "images/projekte/congstar-partnernet/thumbleiste/congstar-partnernet-01.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/congstar-partnernet/thumbleiste/congstar-partnernet-01.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/congstar-partnernet/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)
  
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Portal", "https://congstar-partnernet.de/"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung", "produktion"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js"],
        },
      ]

    return (
        <Layout>
            <SEO
                title="congstar Partnernet"
                description="Q:marketing realisiert B2B-Lösung für congstar."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            {/* <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage={data.heropic.childImageSharp.gatsbyImageData}>                 */}
            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1 className="nomargin"><span className="lineBackgroundWhite">congstar Partnernet</span></h1>
                        <p className="introtext lineBackgroundWhite py-2">Einheitliche Markenidentität durch professionelle Werbemittelvorlagen für congstar-Vertriebspartner.</p>
                        <AnchorLink to="/projekte/b2b-platforms/congstar-partnernet#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                              
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Der gesamte Prozess der Werbeproduktion für Vertriebspartner wird online gesteuert.",
                        "Bereitstellung von aktuellen Werbevorlagen wie Anzeigen oder Handzettel.",
                        "Eine Statistikfunktion liefert Erkenntnisse über den Einsatz von besonders gefragten Werbemitteln in verschiedenen Regionen.",
                        "Q:MARKETING hat das congstar Partnernet konzipiert und entwickelt, hostet und betreibt die Plattform und kümmert sich auch um die Pflege der angebotenen Werbemittel."
                    ]}
                />
            </ListenModule>
            

            <Specs data={specsData} />            

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

            {/* navigation */}            
            <Navigation location={props.location} />            

        </Layout>
    )
}

export default CongstarPartnerwelt